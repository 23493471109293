exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-main-blocks-1-block-index-js": () => import("./../../../src/pages/main/blocks/1 block/index.js" /* webpackChunkName: "component---src-pages-main-blocks-1-block-index-js" */),
  "component---src-pages-main-blocks-2-block-index-js": () => import("./../../../src/pages/main/blocks/2 block/index.js" /* webpackChunkName: "component---src-pages-main-blocks-2-block-index-js" */),
  "component---src-pages-main-blocks-3-block-index-js": () => import("./../../../src/pages/main/blocks/3 block/index.js" /* webpackChunkName: "component---src-pages-main-blocks-3-block-index-js" */),
  "component---src-pages-main-blocks-4-block-index-js": () => import("./../../../src/pages/main/blocks/4 block/index.js" /* webpackChunkName: "component---src-pages-main-blocks-4-block-index-js" */),
  "component---src-pages-main-blocks-5-block-index-js": () => import("./../../../src/pages/main/blocks/5 block/index.js" /* webpackChunkName: "component---src-pages-main-blocks-5-block-index-js" */),
  "component---src-pages-main-blocks-6-block-index-js": () => import("./../../../src/pages/main/blocks/6 block/index.js" /* webpackChunkName: "component---src-pages-main-blocks-6-block-index-js" */),
  "component---src-pages-main-blocks-7-block-index-js": () => import("./../../../src/pages/main/blocks/7 block/index.js" /* webpackChunkName: "component---src-pages-main-blocks-7-block-index-js" */),
  "component---src-pages-main-left-menu-jsx": () => import("./../../../src/pages/main/left-menu.jsx" /* webpackChunkName: "component---src-pages-main-left-menu-jsx" */),
  "component---src-templates-our-project-page-js": () => import("./../../../src/templates/our-project-page.js" /* webpackChunkName: "component---src-templates-our-project-page-js" */)
}


import React, {useContext} from "react"
import {makeStyles} from "@mui/styles";
import LeftMenu from "../pages/main/left-menu";
import {StateContext} from "../providers/context";

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        backgroundColor: theme.palette.black.main,
        width: '100vw',
        height: 'calc(100dvh - 100px)',
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
        position: 'relative',

        [theme.breakpoints.only('smallMobile')]: {
            height: 'calc(100dvh - 52px)',
        },
    },
    hide: {
        display: 'none',
    }
}));

const ContentContainer = ({children, ...props}) => {
    const {activeItem, runBlockAnimationByIndex} = useContext(StateContext);
    const classes = useStyles();

    return <div className={classes.pageContainer}>
        <LeftMenu
            activeItem={activeItem}
            changeActiveItem={runBlockAnimationByIndex}
            {...props}
        />
        {children}
    </div>
}

export default ContentContainer
import React from "react"
import './style.css'
import cx from "classnames";

const Menu = ({isMenuOpened, toggleMenu}) => {
    return <div className={cx('menu', {['activeMenu']: isMenuOpened,})} onClick={toggleMenu}>
        <div/>
    </div>
}

export default Menu
import React, {createContext, useEffect, useRef, useState} from 'react';
import {gsap} from "gsap";

export const StateContext = createContext({});

let activeIndex = 0;
let isScrollInProgress = false;
let touchPadStartPosition = null;
let timeoutId = null;
let scrollListener = null;
let touchStartListener = null;
let touchMoveListener = null;

function StateProvider({children, ...props}) {
    const [isMenuOpened, toggleMenu] = useState(false);
    const [isScrollDown, setIsScrollDown] = useState(false)
    const [activeItem, setActiveItem] = useState(0)
    let blockRefs = useRef({current: []});

    const runBlockAnimationByIndex = async (index) => {
        if (index === activeIndex) {
            return
        }
        const els = blockRefs.current;
        if (index < activeIndex) {
            let tween = gsap.fromTo(els[index], {
                y: '-100vh',
            }, {
                y: '0vh',
                duration: 1
            });

            let tween2 = gsap.fromTo(els[activeIndex], {
                y: '0vh',
            }, {
                y: '100vh',
                duration: 1
            });

            tween.restart();
            tween2.restart();
        } else {
            let tween = gsap.fromTo(els[index], {
                y: '100vh',
            }, {
                y: '0vh',
                duration: 1
            });

            let tween2 = gsap.fromTo(els[activeIndex], {
                y: '0vh',
            }, {
                y: '-100vh',
                duration: 1.2
            });
            tween.restart();
            tween2.restart();
        }
        setIsScrollDown(activeIndex < index)
        setTimeout(function () {
            activeIndex = index;
            setActiveItem(index)
        }, 0)
    }

    const debounce = (wait) => {
        if (props.location.pathname === '/') {
            let yDelta = 0;
            return (args) => {
                yDelta += args.deltaY;
                if ((yDelta > 150 || yDelta < -150) && !isScrollInProgress && ((activeIndex > 0 && args.deltaY < 0) || (activeIndex < 6 && args.deltaY > 0))) {
                    isScrollInProgress = true;
                    runBlockAnimationByIndex(activeIndex + (args.deltaY > 0 ? 1 : -1));
                    timeoutId = window.setTimeout(() => {
                        isScrollInProgress = false
                        window.clearTimeout(timeoutId);
                        yDelta = 0;
                    }, wait);
                }
            };
        }
    }

    function touchStart(event) {
        if (props.location.pathname === '/') {
            if (!isScrollInProgress) {
                touchPadStartPosition = event.touches[0].pageY;
            }
        }
    }

    function touchMove(event) {
        if (props.location.pathname === '/') {
            let offset = touchPadStartPosition - event.touches[0].pageY;
            if (!isScrollInProgress && (offset > 150 || offset < -150) && ((activeIndex > 0 && offset < 0) || (activeIndex < 6 && offset > 0))) {
                isScrollInProgress = true;
                runBlockAnimationByIndex(activeIndex + (offset > 150 ? 1 : -1));
                timeoutId = window.setTimeout(() => {
                    isScrollInProgress = false
                    window.clearTimeout(timeoutId);
                }, 1000);
            }
            return offset;
        }
    }

    useEffect(() => {
        if (!scrollListener) {
            scrollListener = debounce(1300);
            touchStartListener = touchStart;
            touchMoveListener = touchMove;
        }
        if (props.location.pathname === '/') {
            // web
            document.addEventListener('wheel', scrollListener);

            // tablet/mobile
            document.addEventListener("touchstart", touchStartListener, false);
            document.addEventListener("touchmove", touchMoveListener, false);
        } else {
            // web
            document.removeEventListener('wheel', scrollListener);

            // tablet/mobile
            document.removeEventListener("touchstart", touchStartListener, false);
            document.removeEventListener("touchmove", touchMoveListener, false);
        }
    }, [props.location.pathname]);

    return (
        <StateContext.Provider value={{
            isMenuOpened,
            toggleMenu,
            activeItem,
            blockRefs,
            isScrollDown,
            runBlockAnimationByIndex,
        }}>
            {children}
        </StateContext.Provider>
    );
}

export default StateProvider;

import React, {useContext} from "react"
import {makeStyles} from "@mui/styles";
import cx from 'classnames';
import {graphql, navigate, useStaticQuery} from "gatsby";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {StateContext} from "../../providers/context";
import {StaticImage} from "gatsby-plugin-image";

const useStyles = makeStyles((theme) => {
    return {
        header: {
            height: 'calc(100dvh - 100px)',
            width: '100vw',
            position: 'absolute',
            transform: 'translateX(200%)',
            borderBottom: `1px solid ${theme.palette.grey.main}`,
            display: 'flex',
            opacity: 0,
            backgroundColor: theme.palette.black.main,
            zIndex: 2,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'column',
            transition: 'opacity 600ms linear, transform 600ms linear',
        },
        headerSmallSmartphone: {
            height: 'calc(100dvh - 52px)',
        },
        menuOpened: {
            transform: 'translateX(0px) translateY(0px) scale(1)',
            opacity: 1,
            transition: 'opacity 600ms linear, transform 600ms linear',
        },
        logo: {
            height: 32,
            cursor: 'pointer',
        },
        logoSmallSmartphone: {
            height: 16,
        },
        menuContainerWeb: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '40px 20px',
            gap: 36,
        },
        menuLink: {
            width: '100vw',
            color: theme.palette.grey.second,
            textTransform: "uppercase",
            fontFamily: 'Montserrat',
            fontSize: 16,
            fontWeight: 700,
            transition: 'color 1000ms linear, text-decoration-color 300ms linear',
            textDecoration: 'underline',
            textDecorationColor: 'transparent',
            textUnderlineOffset: '4px',
            textDecorationThickness: 2,
            cursor: 'pointer',

            '&:hover': {
                textDecorationColor: theme.palette.orange.main,
                transition: 'text-decoration-color 300ms linear',
            }
        },
        activeMenuLink: {
            color: theme.palette.orange.main,
            transition: 'color 1000ms linear, text-decoration-color 600ms linear',
        },
        contactContainer: {
            flex: 'auto',
            borderBottom: `1px solid ${theme.palette.grey.main}`,
            borderTop: `1px solid ${theme.palette.grey.main}`,
            width: '100vw',
            color: theme.palette.grey.second,
            textTransform: "uppercase",
            fontFamily: 'Oswald',
            fontSize: 46,
            fontWeight: 500,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 40,
            flexDirection: 'column',

            [theme.breakpoints.down('tablet')]: {
                padding: 20,
                gap: 20,
            },

        },
        contactContainerSmallSmartphone: {
            fontSize: 28,
        },
        followUsContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 32,
        },
        image: {
            height: 48,
            width: 48,
            transition: 'background-color 300ms linear',
            backgroundColor: theme.palette.grey.second,

            '&:hover': {
                backgroundColor: theme.palette.orange.main,
                transition: 'background-color 300ms linear',
            },

            '& img': {
                filter: 'brightness(0%)',
                transition: 'filter 300ms linear',

                '&:hover': {
                    filter: 'brightness(100%)',
                    transition: 'filter 300ms linear',
                },
            },
        },
        footer: {
            height: 120,
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.grey.second,
            fontFamily: 'Montserrat',
            fontSize: 16,
        },
        footerSmallSmartphone: {
            height: 60,
        },
    }
});

const TAB_TITLES = [
    'What We Do',
    'Our projects',
    'Who we are',
    'Clients',
    'Contact Us',
];


const SideMenu = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmallMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const {isMenuOpened, toggleMenu, activeItem, runBlockAnimationByIndex} = useContext(StateContext);

    const {strapiContactUs} = useStaticQuery(graphql`
      query {
        strapiContactUs {
          Behance_url
          FB_url
          Twitter_url
          Linkedin_url
          Copyright
        }
      }
    `)

    return <div className={cx(classes.header, {
        [classes.menuOpened]: isMenuOpened,
        [classes.headerSmallSmartphone]: isSmallMobile
    })}>
        <div className={classes.menuContainerWeb}>
            {
                TAB_TITLES.map((item, index) => {
                    return <div key={item}
                                onClick={() => {
                                    runBlockAnimationByIndex(index + 1)
                                    toggleMenu();
                                    if (props.location.pathname !== '/') {
                                        navigate("/")
                                    }
                                }}
                                className={cx(classes.menuLink, {
                                    [classes.activeMenuLink]: props.location.pathname === '/' && activeItem === index + 1
                                })}
                    >
                        {item}
                    </div>
                })
            }
        </div>
        <div className={cx(classes.contactContainer, {
            [classes.contactContainerSmallSmartphone]: isSmallMobile,
        })}>
            {'Follow Us'}
            <div className={classes.followUsContainer}>
                <a href={strapiContactUs.FB_url} target="_blank">
                    <StaticImage src={"./../../assets/follow_us/FB.webp"}
                                 alt="FB"
                                 className={cx(classes.image, {
                                     [classes.logoSmallSmartphone]: isSmallMobile
                                 })}
                    />
                </a>
                <a href={strapiContactUs.Linkedin_url} target="_blank">
                    <StaticImage src={"./../../assets/follow_us/Linkedin.webp"}
                                 alt="Linkedin"
                                 className={cx(classes.image, {
                                     [classes.logoSmallSmartphone]: isSmallMobile
                                 })}
                    />
                </a>
                <a href={strapiContactUs.Behance_url} target="_blank">
                    <StaticImage src={"./../../assets/follow_us/Behance.webp"}
                                 alt="Behance"
                                 className={cx(classes.image, {
                                     [classes.logoSmallSmartphone]: isSmallMobile
                                 })}
                    />
                </a>
                <a href={strapiContactUs.Twitter_url} target="_blank">
                    <StaticImage src={"./../../assets/follow_us/Twitter.webp"}
                                 alt="Twitter"
                                 className={cx(classes.image, {
                                     [classes.logoSmallSmartphone]: isSmallMobile
                                 })}
                    />
                </a>
            </div>
        </div>
        <div className={cx(classes.footer, {
            [classes.footerSmallSmartphone]: isSmallMobile
        })}>{strapiContactUs.Copyright}</div>
    </div>
}

export default SideMenu

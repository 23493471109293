import React, {useContext} from "react"
import {makeStyles} from "@mui/styles";
import cx from 'classnames';
import Menu from "../components/menu/menu";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {StateContext} from "../providers/context";
import {navigate} from "gatsby";
import logo from "./../assets/logo.svg" ;

const useStyles = makeStyles((theme) => {
    return {
        header: {
            height: '100px',
            borderBottom: `1px solid ${theme.palette.grey.main}`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 40px'
        },
        headerSmallSmartphone: {
            height: '52px',
            padding: '0 12px'
        },
        logo: {
            height: '32px',
            cursor: 'pointer',

            '& div[data-placeholder-image]': {
                backgroundColor: '#0E0E0E !important',
            }
        },
        logoSmallSmartphone: {
            height: 16,
        },
        menuContainerWeb: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 52,
        },
        menuLink: {
            color: theme.palette.grey.second,
            textTransform: "uppercase",
            fontFamily: 'Montserrat',
            fontSize: 15,
            fontWeight: 700,
            transition: 'color 1000ms linear, text-decoration-color 600ms linear',
            textDecoration: 'underline',
            textDecorationColor: 'transparent',
            textUnderlineOffset: '4px',
            textDecorationThickness: 2,
            cursor: 'pointer',

            '&:hover': {
                textDecorationColor: theme.palette.orange.main,
                transition: 'text-decoration-color 600ms linear',
            }
        },
        activeMenuLink: {
            color: theme.palette.orange.main,
            transition: 'color 1000ms linear, text-decoration-color 600ms linear',
        },
    }
});

const TAB_TITLES = [
    'What We Do',
    'Our projects',
    'Who we are',
    'Clients',
    'Contact Us',
];

const Navbar = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmallMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const isLaptopAndMore = useMediaQuery(theme.breakpoints.up('laptop'));
    const {isMenuOpened, toggleMenu, activeItem, runBlockAnimationByIndex} = useContext(StateContext);

    return <div className={cx(classes.header, {
        [classes.headerSmallSmartphone]: isSmallMobile
    })}>
        <div
            onClick={() => {
                runBlockAnimationByIndex(0);
                if (props.location.pathname !== '/') {
                    navigate("/")
                }
                toggleMenu(false);
            }}>
            <img src={logo}
                         alt="Logo"
                         className={cx(classes.logo, {
                             [classes.logoSmallSmartphone]: isSmallMobile
                         })}
            />
        </div>
        {
            isLaptopAndMore ?
                <div className={classes.menuContainerWeb}>
                    {
                        TAB_TITLES.map((item, index) => {
                            return <div key={item}
                                        onClick={() => {
                                            runBlockAnimationByIndex(index + 1)
                                            if (props.location.pathname !== '/') {
                                                navigate("/")
                                            }
                                        }}
                                        className={cx(classes.menuLink, {
                                            [classes.activeMenuLink]: props.location.pathname === '/' && activeItem === index + 1
                                        })}
                            >
                                {item}
                            </div>
                        })
                    }
                </div>
                : <Menu
                    isMenuOpened={isMenuOpened}
                    toggleMenu={() => toggleMenu(!isMenuOpened)}
                />
        }
    </div>
}

export default Navbar
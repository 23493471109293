import React, {useEffect, useState} from "react"
import {ThemeProvider} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import {ThemeProvider as ThemeProviderLegacy} from "@mui/styles";
import Navbar from "./navbar"
import SideMenu from "../components/menu/side-menu";
import ContentContainer from "./content-conteiner";
import StateProvider from "../providers/context";

const theme = createTheme({
    palette: {
        black: {
            main: '#0E0E0E',
        },
        grey: {
            main: '#FFFDED80',
            second: '#FFFDED',
        },
        orange: {
            main: '#F49800'
        },
        success: {
            main: '#F49800'
        }
    },
    breakpoints: {
        values: {
            smallMobile: 0,
            mobile: 385,
            tablet: 640,
            laptop: 1025,
            desktop: 1200,
        },
    },
});

const Layout = ({children, ...props}) => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setTimeout((() => setIsMounted(true)), 500);
    }, []);

    return <ThemeProvider theme={theme}>
        <ThemeProviderLegacy theme={theme}>
            <StateProvider {...props}>
                {isMounted
                    ? <div className={'root-container'}>
                        <Navbar {...props}/>
                        <SideMenu {...props}/>
                        <ContentContainer {...props}>
                            {children}
                        </ContentContainer>
                    </div>
                    : <></>
                }
            </StateProvider>
        </ThemeProviderLegacy>
    </ThemeProvider>
}

export default Layout
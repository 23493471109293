import React from "react"
import {makeStyles} from "@mui/styles";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {navigate} from "gatsby";
import arrow from "../../assets/right_arrow.webp";

const useStyles = makeStyles((theme) => ({
    leftMenuContainer: {
        backgroundColor: theme.palette.black.main,
        height: 'calc(100dvh - 100px)',
        width: 100,
        minWidth: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 40,
        alignItems: "center",
        borderRight: `1px solid ${theme.palette.grey.main}`,
        zIndex: 2,

        [theme.breakpoints.only('smallMobile')]: {
            height: 'calc(100dvh - 52px)',
        },
    },
    dotsContainer: {
        height: '100%',
        width: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 40,
        alignItems: "center",
    },
    leftMenuItem: {
        borderRadius: '50%',
        border: `1px solid ${theme.palette.grey.main}`,
        width: 16,
        height: 16,
        backgroundColor: "transparent",
        transition: 'background-color 1000ms linear, border 300ms linear',
        cursor: 'pointer',

        '&:hover': {
            border: `1px solid ${theme.palette.orange.main}`,
            transition: 'background-color 1000ms linear, border 300ms linear',
        }
    },
    activeLeftMenuItem: {
        borderRadius: '50%',
        border: `1px solid ${theme.palette.orange.main}`,
        backgroundColor: theme.palette.orange.main,
        width: 16,
        height: 16,
        transition: 'background-color 1000ms linear, border 300ms linear',
    },
    arrow: {
        width: 49,
        height: 49,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        transition: 'background-color 600ms linear, filter 300ms linear',
        '&:hover': {
            backgroundColor: 'white',
            transition: 'background-color 600ms linear, filter 300ms linear',
        },

        '& img': {
            width: 25,
            height: 25,
            fill: theme.palette.orange.main,
            transition: 'background-color 600ms linear, filter 300ms linear',
            '&:hover': {
                filter: 'brightness(0%)',
                transition: 'background-color 600ms linear, filter 300ms linear',
            },
        },
        [theme.breakpoints.up('tablet')]: {
            width: 104,
            height: 104,

            '& img': {
                width: 50,
                height: 50,
            }
        },
    },
    backButtonContainer: {
        display: 'flex',
        position: 'absolute',
        top: 0,
        cursor: 'pointer',
    },
}));

const LeftMenu = ({activeItem, changeActiveItem, ...props}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isLaptopAndMore = useMediaQuery(theme.breakpoints.up('laptop'));
    const isProjectPage = props.uri.startsWith('/project/');

    return <>
        {
            isLaptopAndMore &&
            <div className={classes.leftMenuContainer}>
                <div className={classes.dotsContainer}
                     style={{
                         opacity: isProjectPage ? 0 : 1,
                         transition: 'opacity 500ms linear',
                     }}
                >{[0, 1, 2, 3, 4, 5].map(item => {
                    return <div key={`${item}_menu_item`}
                                className={activeItem === item && props.location.pathname === '/' ? classes.activeLeftMenuItem : classes.leftMenuItem}
                                onClick={() => {
                                    if (props.location.pathname !== '/') {
                                        navigate("/")
                                    }
                                    changeActiveItem(item)
                                }}/>
                })}
                </div>
                <div className={classes.backButtonContainer}
                     style={{
                         opacity: isProjectPage ? 1 : 0,
                         transition: 'opacity 500ms linear',
                     }}
                     onClick={() => {
                         navigate("/")
                     }}
                >
                    <div className={classes.arrow}>
                        <img src={arrow}
                                     alt="Arrow"
                                     style={{transform: 'rotate(180deg)'}}
                        />
                    </div>
                </div>
            </div>
        }
    </>
}

export default LeftMenu